import React from 'react';
import Modal from 'react-modal';
import App from './app';

Modal.setAppElement('#___gatsby');

const IndexPage = () => {
    return <App />;
};
export default IndexPage;
